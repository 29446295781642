import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

const role = Cookies.get("token_role"); 

export const connectLoginUrlForSales = 
 "https://absyzsoftwareconsultingpvtltd--devinvoice.sandbox.my.salesforce.com/services/oauth2/authorize?client_id=3MVG9bFKi1uCqVFUXnTiS5WkVQ.vVHbZHSIwF_hs.IpxbSagHxqukc4RQKJbfBj30eSZmcHxgDomfi0S3e59e&redirect_uri=https://ape.absyz.com/connect-login&response_type=token"
//  export const connectLoginUrlForFinance = 
//  "https://absyzconnect--uatpartial.sandbox.my.site.com/Connect/services/oauth2/authorize?response_type=token&client_id=3MVG9plE98f2IjfYhJt7vcYUU7d7RCpPuXvCKxcvPNGTSBT_Q8TnxejvSwTclOGh3B47E5NEs5HTrT.Ao82oc&redirect_uri=https://ape.absyz.com/connect-login";


 export const employeeDetailsSales = 
 "https://absyzsoftwareconsultingpvtltd--devinvoice.sandbox.my.salesforce.com/services/apexrest/Employeedetails/"
//  export const employeeDetailsFinance = 
// "https://absyzconnect--uatpartial.sandbox.my.site.com/services/apexrest/Employeedetails/";
 

export const connectDetails = "https://absyzconnect--uatpartial.sandbox.my.site.com/Connect/services/oauth2/authorize?response_type=token&client_id=3MVG9plE98f2IjfYhJt7vcYUU7d7RCpPuXvCKxcvPNGTSBT_Q8TnxejvSwTclOGh3B47E5NEs5HTrT.Ao82oc&redirect_uri=https://ape.absyz.com/connect-login";
//  "https://absyzconnect--devcrm.sandbox.my.site.com/Connect/services/oauth2/authorize?response_type=token&client_id=3MVG9Se4BnchkASmaADZSJyB3PptnmMdavQJ5nQ.AlhxVslZPVEW08Z59XdM7UjlRkkIAxS7WdSHlO4SPnvwK&redirect_uri=https://ape.absyz.com/connect-login";
export const connectEmployeeDetails =  role === "Sales" ? 
" https://absyzsoftwareconsultingpvtltd--devinvoice.sandbox.my.salesforce.com/services/apexrest/Employeedetails/" :
"https://absyzconnect--uatpartial.sandbox.my.site.com/services/apexrest/Employeedetails/";

// "https://absyzconnect--devcrm.sandbox.my.site.com/services/apexrest/rewards/employee/";

export const connectDetailsSales=  "https://absyzsoftwareconsultingpvtltd--devinvoice.sandbox.lightning.force.com/services/oauth2/authorize?response_type=token&client_id=3MVG9bFKi1uCqVFUXnTiS5WkVQyzNqK4_S..EOlwvWFkxMGo2kwvnouw68FBDB8sOQosPWODDOSlYKDNghIQh&redirect_uri=https://ape.absyz.com/connect-login";

export const connectEmployeeDetailsSales= 
// "https://absyzsoftwareconsultingpvtltd--devinvoice.sandbox.lightning.force.com/services/apexrest/Employeedetails/"
" https://absyzsoftwareconsultingpvtltd--devinvoice.sandbox.my.salesforce.com/services/apexrest/Employeedetails/";
// export const connectDetails = 
// "https://absyzconnect--uatpartial.sandbox.my.site.com/Connect/services/oauth2/authorize?response_type=token&client_id=3MVG9plE98f2IjfYhJt7vcYUU7d7RCpPuXvCKxcvPNGTSBT_Q8TnxejvSwTclOGh3B47E5NEs5HTrT.Ao82oc&redirect_uri=https://ape.absyz.com/connect-login";
// //  "https://absyzconnect--devcrm.sandbox.my.site.com/Connect/services/oauth2/authorize?response_type=token&client_id=3MVG9Se4BnchkASmaADZSJyB3PptnmMdavQJ5nQ.AlhxVslZPVEW08Z59XdM7UjlRkkIAxS7WdSHlO4SPnvwK&redirect_uri=https://ape.absyz.com/connect-login";
// export const connectEmployeeDetails = 
// "https://absyzconnect--uatpartial.sandbox.my.site.com/services/apexrest/Employeedetails/";
// // "https://absyzconnect--devcrm.sandbox.my.site.com/services/apexrest/rewards/employee/";

// export const salesDetails  = "https://absyzsoftwareconsultingpvtltd--devinvoice.sandbox.lightning.force.com/services/oauth2/authorize?response_type=token&client_id=3MVG9bFKi1uCqVFUXnTiS5WkVQyzNqK4_S..EOlwvWFkxMGo2kwvnouw68FBDB8sOQosPWODDOSlYKDNghIQh&redirect_uri=https://ape.absyz.com/connect-login"

console.log(role, "tokenrole");

function compareObjects(obj1: any, obj2: any) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (let key of keys1) {
    if (
      typeof obj1[key] === "object" &&
      obj1[key] !== null &&
      !Array.isArray(obj1[key])
    ) {
      if (compareObjects(obj1[key], obj2[key])) {
        return true;
      }
    } else if (Array.isArray(obj1[key])) {
      if (obj1[key]?.length !== obj2[key]?.length) {
        return true;
      }
      for (let i = 0; i < obj1[key]?.length; i++) {
        if (compareObjects(obj1[key][i], obj2[key][i])) {
          return true;
        }
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
  }

  return false;
}

export const compareMultiplePairs = (...pairs: any) => {
  for (let [obj1, obj2] of pairs) {
    if (compareObjects(obj1, obj2)) {
      return true;
    }
  }
  return false;
};

// const secretKey = "Absyz@12";

// const expiryDuration = 157680000000; // Expiry duration in milliseconds (e.g., 1 hour)

// interface EncryptedPayload {
//   message: string;
//   expiry: number;
// }

// export const encryptMessageWithExpiry = (message: string): string => {
//   try {
//     const expiryTimestamp = Date.now() + expiryDuration; // Set the expiry timestamp

//     // Create payload with message and expiry timestamp
//     const payload: EncryptedPayload = {
//       message,
//       expiry: expiryTimestamp,
//     };

//     // Convert payload to JSON and encrypt
//     const encrypted = CryptoJS.AES.encrypt(
//       JSON.stringify(payload),
//       secretKey
//     ).toString();

//     console.log("Encrypted message with expiry:", encrypted);
//     return encrypted;
//   } catch (error) {
//     console.error("Encryption error:", error);
//     throw error;
//   }
// };

// export const verifyToken = async (tokenToDecrypt: any): Promise<string> => {
//   console.log("Initial token to decode:", tokenToDecrypt);
//   try {
//     let decodedString = atob(tokenToDecrypt); 
//     console.log("First decoding result:", decodedString);
    
//     let decodedAgain = atob(decodedString);
//     console.log("Second decoding result:", decodedAgain);
//     Cookies.set("token_role",decodedAgain);
    
//     return decodedAgain; 
//   } catch (error) {
//     console.error("Error decoding token:", error);
//     throw new Error("Token decoding failed");
//   }
// };
// let val=verifyToken();
